import React, { useEffect, useRef } from "react";
import { useSnapshot } from "valtio";
import coachify_img from "../../../assets/img/coachify.png";
import coachify_1_img from "./ads/coachify_1.jpg";
import coachify_2_img from "./ads/coachify_2.jpg";
import coachify_3_img from "./ads/coachify_3.jpg";
import LogoIcon from "../../../desktop/public/icons/icon.svg?react";
import { platformState } from "../states/uiStates";

const COACH_ADS = [
	{
		img: coachify_img,
		link: "https://coachify.gg/?utm_campaign=map_tools_for_esports_coaches&utm_source=riftkit_map&utm_medium=referral",
	},
	{
		img: coachify_1_img,
		link: "https://coachify.gg/?utm_campaign=make_money_coaching&utm_source=riftkit_map&utm_medium=referral",
	},
	{
		img: coachify_2_img,
		link: "https://coachify.gg/?utm_campaign=lead_gamers_to_glory&utm_source=riftkit_map&utm_medium=referral",
	},
	{
		img: coachify_3_img,
		link: "https://coachify.gg/?utm_campaign=your_skill_their_victory&utm_source=riftkit_map&utm_medium=referral",
	},
];
const coachAd = COACH_ADS[Math.floor(Math.random() * COACH_ADS.length)];

interface AdViewProps {}

const AdView: React.FC<AdViewProps> = (props) => {
	const ref = useRef<HTMLDivElement>(null);
	const plaformSnap = useSnapshot(platformState);

	useEffect(() => {
		const timer = () => {
			if (!ref.current) return;
			if (!window.adError && !window.adReady) {
				setTimeout(timer, 1000);
				return;
			}

			if (window.adReady) {
				// @ts-ignore
				new OwAd(ref.current!, { size: { width: 400, height: 300 }, owAdTestAd: true });
			}
		};

		if (plaformSnap.platform == "desktop") timer();
	}, [plaformSnap.platform]);

	return (
		<div className={"relative"} style={{ height: 300, width: 400 }}>
			<div
				ref={ref}
				className={"absolute inset-0 flex items-center justify-center opacity-20"}
			>
				<LogoIcon width={280} height={280} />
			</div>
			<div ref={ref} className={"absolute inset-0"}>
				{plaformSnap.platform === "web" && (
					<a href={coachAd.link} target={"_blank"} rel={"noreferrer nofollow"}>
						<img src={coachAd.img} />
					</a>
				)}
			</div>
		</div>
	);
};

export { AdView };
