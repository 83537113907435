import React, { FormEvent, useState } from "react";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import { loadLastSearch } from "../../storage/settings";
import { REGIONS_V5, RegionV5 } from "../../types/riot";
import { useSettingsSnapshot } from "@shared/states/uiStates";

interface SearchSummonerInputProps {
	onSearch: (name: string, region: RegionV5) => void;
}

const SearchSummonerInput: React.FC<SearchSummonerInputProps> = (props) => {
	const settingsSnap = useSettingsSnapshot();

	// if we have a user saved, we use that, otherwise the latest search
	const def = settingsSnap.user
		? {
				name: settingsSnap.user.name,
				region: settingsSnap.user.platform_id,
			}
		: loadLastSearch();

	const [username, setUsername] = useState(def.name || "");
	const [region, setRegion] = useState<RegionV5>(def.region || "americas");

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		// according to the FAQ summoner names are at least 3 char long
		if (username.length < 3) return;
		props.onSearch(username, region);
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className={"flex relative bg-slate-500 p-1 rounded text-white"}>
				<select
					onChange={(e) => setRegion(e.currentTarget.value as RegionV5)}
					value={region}
					className={"bg-slate-600 rounded mr-2 outline-none"}
					data-testid={"select-region"}
				>
					{Object.entries(REGIONS_V5).map(([key, name]) => (
						<option key={key} value={key}>
							{name}
						</option>
					))}
				</select>
				<div className={"flex flex-1"}>
					<input
						id={"name"}
						value={username}
						placeholder={"GameName#1234"}
						onChange={(e) => setUsername(e.currentTarget.value)}
						className={"bg-transparent m-1 outline-none"}
						data-testid={"import-summoner-name"}
					/>
				</div>
				<button
					type={"submit"}
					className={"hover:bg-slate-600 rounded disabled:opacity-30"}
					disabled={username.length < 7}
					data-testid="import-search"
				>
					<SearchIcon width={30} height={30} />
				</button>
			</div>
		</form>
	);
};

export { SearchSummonerInput };
